import { EntityFileOwner } from 'app/constants/EntityFileOwner';
import { EntityFileUsage } from 'app/constants/EntityFileUsage';

import { makeBuilder as b, URLParams, wrapBuilders } from '../URLBuilder';

const apiRoutes = {
  checklist: {
    show: (teamId?: number) => b('/api/v1/checklist', teamId),
  },

  auth: {
    register: () => b('/api/v1/auth/register'),
    login: () => b('/api/v1/auth/login'),
    logout: () => b('/api/v1/auth/logout'),
    checkEmailBeforeLogin: (redirect_to) =>
      b('/api/v1/auth/checkEmailBeforeLogin', { redirect_to }),
    checkEmailPrimaryStatus: () => b('/api/v1/auth/check-email-primary-status'),
    verifyEmail: () => b('/api/v1/auth/email/verify'),
    resendVerificationLink: () => b('/api/v1/auth/email/verify/resend'),
    sendPasswordResetLink: () => b('/api/v1/auth/password/email'),
    resetPassword: () => b('/api/v1/auth/password/reset'),
    magiclink: () => b('/api/v1/auth/magiclink'),
  },

  navigation: {
    main: (coachedMember?) => b('/api/v1/navigation/main', { coachedMember }),
  },

  members: {
    show: (memberId: number) => b(`/api/v1/members/${memberId}`),
    current: (token?: string) => b('/api/v1/members/current', { token }),
    currentReferralLink: () => b('/api/v1/members/current/referral-link'),
    currentMemberState: () => b('/api/v1/members/current/state'),
    currentSendReferralEmail: () => b('/api/v1/members/current/send-referral-email'),
    currentMemberTeams: (params?: URLParams) => b('/api/v1/members/current/teams', params),
    currentMemberManagedTeams: (orgId: number) =>
      b(`/api/v1/members/current/${orgId}/managed-teams`),
    currentMemberTeamsOverview: () => b('/api/v1/members/current/teams/overview'),
    updateMemberPairAccess: () => b('/api/v1/members/current/pairing-view'),
    update: (memberID: number) => b(`/api/v1/members/${memberID}`),
    updateSelfRole: () => b('/api/v1/members/updateSelfRole'),
    teammatesWithPerspective: (memberId: number) =>
      b(`/api/v1/members/${memberId}/teammates-with-perspective`),
    saveCoachedMember: () => b('/api/v1/coached-members/save'),
    loadCoachData: () => b('/api/v1/members/coach_data'),
    entityStates: () => b('/api/v1/members/current/entity-states'),
    guestMember: (token: string) => b('/api/v1/members/guest', token),
    confirmations: {
      guestConfirm: (token?: string, exerciseId?: number) =>
        b('/api/v1/members/guest/confirm', { token, exerciseId }),
    },
    entityLabels: () => b('/api/v1/entities/member_labels'),
    getSharedLinkInfo: () => b('api/v1/members/get_shared_link_info'),
  },

  onboarding: {
    tool: {
      introduction: (token: string) => b(`api/v1/onboarding/${token}/introduction`),
    },
    completeOnboarding: () => b('/api/v1/onboarding/complete-onboarding'),
    createAutomaticTeam: () => b('/api/v1/onboarding/create-auto-team'),
    customOnboarding: {
      details: (token: string) => b(`/onboarding/${token}/details`),
      activationContent: (token: string) => b(`/api/v1/onboarding/initiative-content/${token}`),
      createTeamExercise: (onboardingLinkId) =>
        b(`api/v1/onboarding/${onboardingLinkId}/exercise/team`),
    },
    onBoardingLinks: {
      create: (orgId: number, type: string) =>
        b(`/api/v1/onboarding/${orgId}/onboarding-links/${type}`),
      delete: (onboardingLinkId: number) =>
        b(`/api/v1/onboarding/onboarding-links/${onboardingLinkId}`),
      update: (onboardingLinkId: number) =>
        b(`/api/v1/onboarding/onboarding-links/${onboardingLinkId}`),
      show: (onboardingLinkId: number) =>
        b(`/api/v1/onboarding/onboarding-links/${onboardingLinkId}`),
    },
  },

  teams: {
    create: () => b('/api/v1/teams'),
    list: (token?: string) => b('/api/v1/teams', { token }),
    show: (teamID) => b(`/api/v1/teams/${teamID}`),
    membersWithMBTI: (teamID) => b(`/api/v1/teams/${teamID}/members-with-mbti`),
    otherTeams: (teamId) => b(`/api/v1/teams/${teamId}/other-teams`),
    update: (teamId) => b(`/api/v1/teams/${teamId}`),
    assignSecondaryTeamManager: (teamId: number, memberId?: number) =>
      b(`/api/v1/teams/${teamId}/manager`, memberId),
    revokeSecondaryTeamManager: (teamId: number, memberId: number) =>
      b(`/api/v1/teams/${teamId}/manager/revoke`, memberId),
    sendEmailToSelectedMembers: (teamId: number) =>
      b(`/api/v1/teams/${teamId}/send-email-to-selected-members`),
    showForGuest: (teamID: number) => b(`/api/v1/teams/guest/${teamID}`),
    exerciseTypes: (teamID: number, exerciseTypeID: number) =>
      b(`/api/v1/teams/${teamID}/exercise-types/${exerciseTypeID}`),
    entityStates: (teamID: number, query?: URLParams) =>
      b(`/api/v1/teams/${teamID}/entity-states`, query),
    emailActivities: (teamId: number) => b(`/api/v1/email-activities/teams/${teamId}`),
    memberTeams: (orgId?: number) => b('/api/v1/teams/current-member', orgId),

    exercises: {
      list: (teamID: number) => b(`/api/v1/teams/${teamID}/exercises`),
      minimalList: (teamID: number) => b(`/api/v1/teams/${teamID}/minimal_exercises`),
      sendReminders: (teamID: number, exerciseID: number) =>
        b(`/api/v1/teams/${teamID}/exercises/${exerciseID}/send-reminders`),
      sendInvites: (teamID: number, exerciseID: number) =>
        b(`/api/v1/teams/${teamID}/exercises/${exerciseID}/send-invites`),
      dismissPrompt: (teamID: number, exerciseID: number) =>
        b(`/api/v1/teams/${teamID}/exercises/${exerciseID}/dismiss-prompt`),
      undoDismissPrompt: (teamID: number, exerciseID: number) =>
        b(`/api/v1/teams/${teamID}/exercises/${exerciseID}/undo-dismiss-prompt`),
    },

    members: {
      list: (teamId) => b(`/api/v1/teams/${teamId}/members`),
      add: (teamId) => b(`/api/v1/teams/${teamId}/members/add`),
      import: (teamId) => b(`/api/v1/teams/${teamId}/members/import`),
      remove: (teamId, memberId) => b(`/api/v1/teams/${teamId}/members/${memberId}`),
    },

    pulses: {
      list: (teamId: number) => b(`/api/v1/teams/${teamId}/pulses`),
      show: (teamId: number, pulseId: number) => b(`/api/v1/teams/${teamId}/pulses/${pulseId}`),
    },
  },

  pulses: {
    pulseByToken: (token: string) => b(`/api/v1/pulses/${token}`),
    responsesByToken: (token: string) => b(`/api/v1/pulses/${token}/responses`),
    responses: (id: number) => b(`/api/v1/pulses/${id}/responses`),
    showForGuest: (teamID: number) => b(`/api/v1/teams/guest/${teamID}`),
    participantsPool: (pulseId: number) => b(`/api/v1/pulses/${pulseId}/participants_pool`),
    removeParticipant: (token: string, memberId: number) =>
      b(`/api/v1/pulses/${token}/${memberId}`),

    templateGroups: {
      member: (memberId: number) => b(`/api/v1/members/${memberId}/pulses/template-groups`),
      team: (teamId: number, templateSourceType?: string) =>
        b(`/api/v1/teams/${teamId}/pulses/template-groups`, templateSourceType),
    },

    team: {
      create: (teamId: number) => b(`api/v1/teams/${teamId}/pulses`),
      update: (teamId: number, pulseId: number) => b(`api/v1/teams/${teamId}/pulses/${pulseId}`),
      updateStatus: (teamId: number, pulseId: number) =>
        b(`api/v1/teams/${teamId}/pulses/${pulseId}/status`),
      participants: (teamId: number, pulseId: number) =>
        b(`/api/v1/teams/${teamId}/pulses/${pulseId}/participants`),
      reminder: (teamId: number, pulseId: number) =>
        b(`/api/v1/teams/${teamId}/pulses/${pulseId}/reminders/send`),
      delete: (teamId: number, pulseId: number) => b(`/api/v1/teams/${teamId}/pulses/${pulseId}`),
      report: (teamId: number, pulseId: number) =>
        b(`/api/v1/teams/${teamId}/pulses/${pulseId}/report`),
      reportByToken: (pulseToken: string) => b(`/api/v1/teams/pulses/${pulseToken}/report`),
    },

    members: {
      list: (memberId: number) => b(`/api/v1/members/${memberId}/pulses`),
      get: (memberId: number, pulseId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}`),
      create: (memberId: number) => b(`/api/v1/members/${memberId}/pulses`),
      update: (memberId: number, pulseId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}`),
      delete: (memberId: number, pulseId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}`),
      participants: (memberId: number, pulseId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}/participants`),
      report: (memberId: number, pulseId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}/report`),
      reminder: (memberId: number, pulseId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}/reminders/send`),
    },

    reminder: {
      default: () => b('/api/v1/pulses/reminder/default'),
      createMemberPulse: (memberId: number, pulseId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}/reminders`),
      createTeamPulse: (teamId: number, pulseId: number) =>
        b(`api/v1/teams/${teamId}/pulses/${pulseId}/reminders`),
      update: (memberId: number, pulseId: number, reminderId: number) =>
        b(`/api/v1/members/${memberId}/pulses/${pulseId}/reminders/${reminderId}`),
      updateTeam: (teamId: number, pulseId: number, reminderId: number) =>
        b(`/api/v1/teams/${teamId}/pulses/${pulseId}/reminders/${reminderId}`),
    },
  },

  perspectives: {
    allTeammateDiffs: () => b('/api/v1/perspectives/diff/all'),
    questions: (token?: string) => b('/api/v1/perspectives/questions', { token }),
    multiScoreDiffs: (token?: string) => b('/api/v1/perspectives/multi-score-diffs', { token }),
    pairReportPreviews: (teamId: number) =>
      b(`/api/v1/perspectives/pair-report-previews/${teamId}`),
    result: (memberId?: number, token?: string) =>
      b('/api/v1/perspectives/result', memberId, { token }),
    retake: (memberId?: number) => b('/api/v1/perspectives/retake', memberId),
    scoreDiffs: (teamID, token?: string) =>
      b(`/api/v1/perspectives/team/${teamID}/score/diffs`, { token }),
    sendInviteToOthers: (token?: string) => b('/api/v1/perspectives/invite', { token }),
    orgParticipants: (token?: string) => b('/api/v1/perspectives/search/participants', { token }),
    pairParticipants: (pairMemberId?: number, filter?: string, token?: string) =>
      b('/api/v1/perspectives/pair-participants', pairMemberId, { filter, token }),
    allAvailableMembers: (pairMemberId?: number, filter?: string, token?: string) =>
      b('/api/v1/perspectives/all-members', pairMemberId, { filter, token }),
    teamNonParticipants: (teamId: number, token?: string) =>
      b('/api/v1/perspectives/team-non-participants', teamId, { token }),
    ensureTeamMembership: (token: string, memberId: number) =>
      b(`/api/v1/perspectives/${token}/ensure-team-membership/${memberId}`),

    nonParticipantsSearch: () => b('/api/v1/perspectives/search/non-participants'),
    managerInsights: (memberId: number, teamId: number) =>
      b('/api/v1/perspectives/manager-insights', memberId, teamId),
    inviter: (token: string) => b(`/api/v1/perspectives/inviter/${token}`),

    report: {
      teamResult: (teamID, token?: string) =>
        b(`/api/v1/perspectives/report/team/${teamID}/result`, { token }),
      surveyAnswers: (token?: string) => b('/api/v1/perspectives/report/survey/answers', { token }),
      personal: (token?: string) => b('/api/v1/perspectives/report/personal', { token }),
      defaultFilteredMembers: (teamID: number) =>
        b(`/api/v1/perspectives/team/${teamID}/default-filtered-members`),
    },
  },

  exercises: {
    create: (teamID: number) => b(`/api/v1/teams/${teamID}/exercises`),
    show: (teamID: number, exerciseID: number) =>
      b(`/api/v1/teams/${teamID}/exercises/${exerciseID}`),
    delete: (exerciseID: number) => b(`/api/v1/exercises/${exerciseID}`),
    close: (exerciseID: number) => b(`/api/v1/exercises/${exerciseID}/close`),
    entityStates: (exerciseID: number, query?: URLParams) =>
      b(`/api/v1/exercises/${exerciseID}/entity-states`, query),
    ownedEntityStates: (query: URLParams) => b('/api/v1/exercises/owned-entity-states', query),
    update: (alignID) => b(`/api/v1/exercises/${alignID}`),
    sendResults: (alignID) => b(`/api/v1/exercises/${alignID}/send-results`),
    saveAnswers: (exerciseID: number) => b(`/api/v1/exercises/${exerciseID}/answers`),
    showByIdOrToken: (idOrToken: number | string) => b(`/api/v1/exercises/${idOrToken}`),
    showForCoach: (id: number) => b(`/api/v1/exercises/${id}/coach`),
    showBySecretCode: (type: string) => b(`/api/v1/exercises/type/${type}`),
    activity: (exerciseID: number) => b(`/api/v1/exercises/${exerciseID}/activity`),

    // Post-survey
    postSurveyQuestions: (exerciseId: number) =>
      b(`/api/v1/exercises/${exerciseId}/survey-questions`),
    savePostSurveyAnswers: (exerciseId: number) => b(`/api/v1/exercises/${exerciseId}/post-survey`),

    // Single Player Mode Exercises
    singlePlayer: {
      list: (memberId: number) => b(`/api/v1/single-players/${memberId}/exercises`), // GET
      create: (memberId: number) => b(`/api/v1/single-players/${memberId}/exercises`), // POST
      show: (memberId: number, exerciseId: number) =>
        b(`/api/v1/single-players/${memberId}/exercises/${exerciseId}`), // GET
      convert: (memberId: number, exerciseId: number, teamId: number) =>
        b(`/api/v1/single-players/${memberId}/exercises/${exerciseId}/convert/${teamId}`), // PUT
    },

    commitments: {
      list: (exerciseId: number, token: string) =>
        b(`/api/v1/exercises/${exerciseId}/commitments`, { token }),
      show: (exerciseId: number, id: number, token: string) =>
        b(`/api/v1/exercises/${exerciseId}/commitments/${id}`, { token }),
    },
  },

  exerciseTypes: {
    list: () => b('/api/v1/exercise-types'),
    statement: (exerciseTypeCode: string, statementID: number) =>
      b(`/api/v1/exercise-types/${exerciseTypeCode}/statements/${statementID}`),
    teamExerciseTypes: (teamID: number) => b(`/api/v1/exercise-types/team/${teamID}`),
    orgExerciseTypes: (orgID: number) => b(`/api/v1/exercise-types/org/${orgID}`),
  },

  exerciseReports: {
    show: (token: string) => b(`/api/v1/exercise-reports/${token}`),
    coach: (secretExerciseCode: string, exerciseId: number) =>
      b(`/api/v1/exercise-reports/${secretExerciseCode}/${exerciseId}`),
    testDriveShow: (type: string, testDriveType: string) =>
      b(`/api/v1/exercise-reports/test-drive/${type}/${testDriveType}`),
    subscribe: (token: string) => b(`/api/v1/exercise-reports/${token}/subscribe`),
    thankRespondents: (token: string) => b(`/api/v1/exercise-reports/${token}/thank-respondents`),
  },

  organizations: {
    show: (orgId: number) => b(`/api/v1/organizations/${orgId}`),
    list: () => b('/api/v1/organizations'),
    update: (orgId: number) => b(`/api/v1/organizations/${orgId}`),
    teams: (orgId: number) => b(`/api/v1/organizations/${orgId}/teams`),
    members: (orgId: number) => b(`/api/v1/organizations/${orgId}/members`),
    contracts: (orgId: number) => b(`/api/v1/organizations/${orgId}/contracts`),
    member: (orgId: number, memberId: number) =>
      b(`/api/v1/organizations/${orgId}/members/${memberId}`),
    stats: (orgId: number) => b(`/api/v1/organizations/${orgId}/stats`),
    pulses: (orgId: number) => b(`/api/v1/organizations/${orgId}/pulses`),
    roles: (orgId: number) => b(`/api/v1/organizations/${orgId}/roles`),
    role: (orgId: number, roleId: number) => b(`/api/v1/organizations/${orgId}/roles/${roleId}`),
    saveMemberOrgRole: (orgID: number) => b(`/api/v1/organizations/${orgID}/members/roles`),
    addMembers: (orgId: number) => b(`/api/v1/organizations/${orgId}/members/add`),
    addMemberToNewOrg: () => b('/api/v1/organizations/new/members/add'),
    getBySecretCode: (secretCode: string) => b(`/api/v1/organizations/code/${secretCode}`),
    searchMembers: (orgId: number) => b(`/api/v1/organizations/${orgId}/search/members`),
    anonymize: (memberId: number, organizationId: number) =>
      b(`/api/v1/organizations/${organizationId}/members/anonymize/${memberId}`),
    programs: (orgId: number) => b(`/api/v1/onboarding/organizations/${orgId}/programs/list`),
    allTeams: (orgId: number) => b(`/api/v1/organizations/${orgId}/all/teams`),
    analyticsFilters: (orgId: number, dashboard: string) =>
      b(`/api/v1/organizations/${orgId}/analytics/filters`, { dashboard }),
    memberEngagementAnalytics: (orgId: number) =>
      b(`/api/v1/member-engagements/${orgId}/analytics`),

    reportRollup: {
      filters: (orgId: number) => b(`/api/v1/organizations/${orgId}/report-rollup/filters`),
      rollup: (orgId: number, exerciseTypeId: number) =>
        b(`/api/v1/organizations/${orgId}/report-rollup/${exerciseTypeId}`),
    },

    content: {
      single: (key: string, orgId?: number, contentArgument?: number) =>
        b(`/api/v1/org-contents/${key}`, orgId, contentArgument),
      multi: (orgId: number, teamId?: number) =>
        b(`/api/v1/org-contents/organizations/${orgId}/multi`, {
          team_id: teamId,
        }),
    },
  },

  teamGroupReport: (groupID: number, exerciseID: number) =>
    b(`/api/v1/team-groups/${groupID}/reports/${exerciseID}`),

  features: {
    orgEnabled: (orgId) => b(`/api/v1/features/enabled/organizations/${orgId}`),
  },

  my360s: {
    show: (exerciseId) => b(`/api/v1/exercises/${exerciseId}`),
    memberMy360s: (memberId: number, params?: URLParams) =>
      b(`/api/v1/my360s/member/${memberId}`, params),
    new: (exerciseTypeId: number, memberId?: number) =>
      b(`/api/v1/my360s/new/${exerciseTypeId}`, memberId),
    personalInviteLinks: (exerciseId: number) => b(`/api/v1/my360s/${exerciseId}/personal-links`),
    inviteMembers: (exerciseId: number) => b(`/api/v1/my360s/${exerciseId}/invite`),
    remindMembers: (exerciseId: number) => b(`/api/v1/my360s/${exerciseId}/remind`),
    comments: (exerciseId: number, memberId: number) =>
      b(`/api/v1/my360s/${exerciseId}/comments/${memberId}`),
    templates: (memberId?: number) => b('/api/v1/my360s/templates', memberId),
    highlights: (exerciseTypeId: number) => b(`/api/v1/my360s/${exerciseTypeId}/highlights`),
    colleagues: (params?: URLParams) => b('/api/v1/my360s/colleagues', params),
    manager360ReportFilters: (token: string) =>
      b(`/api/v1/my360s/${token}/manager360/report-filters`),
  },

  manager360: {
    createExercises: () => b('/api/v1/my360s/manager360s'),
  },

  discussionGuides: {
    new: (exerciseId: number) => b(`/api/v1/discussion-guides/${exerciseId}`),
    get: (exerciseId: number) => b(`/api/v1/discussion-guides/${exerciseId}`),
    getTestDrive: (exerciseId: number) => b(`/api/v1/discussion-guides/test-drive/${exerciseId}`),
    update: (guideID: number) => b(`/api/v1/discussion-guides/${guideID}`),
    type: (guideId: number) => b(`/api/v1/discussion-guides/${guideId}/type`),
    launch: (guideID: number) => b(`/api/v1/discussion-guides/${guideID}/launch`),
    prompts: (guideId: number) => b(`/api/v1/discussion-guides/${guideId}/prompts`),
    savePromptAnswer: (guideId: number, promptId: number) =>
      b(`/api/v1/discussion-guides/${guideId}/prompts/${promptId}`),
    getPromptAnswer: (guideId: number) => b(`/api/v1/discussion-guides/${guideId}/prompts/answers`),
  },

  contentSections: {
    show: (name: string) => b('/api/v1/content-sections', name),
  },

  menus: {
    menu: (name?: string, organizationId?: number, teamId?: number) =>
      b(`/api/v1/menus/menu/${name}`, { team: teamId, organization: organizationId }),
    activeModels: (organizationId?: number, teamId?: number) =>
      b('/api/v1/menus/active-models', { organization: organizationId, team: teamId }),
  },

  entity: {
    file: {
      upload: (entityId: number, entityType: EntityFileOwner, usage: EntityFileUsage) =>
        b(`/api/v1/entities/${entityId}/${entityType}/file/${usage}`),
      get: (entityId: number, entityType: string, usage: string, preset: string) => {
        const params = { preset };
        return b(`/api/v1/entities/${entityId}/${entityType}/file/${usage}`, params);
      },
    },
  },

  admin: {
    teams: {
      mergeToTeam: (teamId: number, otherTeamId: number) =>
        b(`/api/v1/admin/teams/${teamId}/merge/${otherTeamId}`),
      promoteToMembers: (teamId: number) => b(`/api/v1/admin/teams/${teamId}/promote-members`),
      team: (teamId: number) => b(`/api/v1/admin/teams/${teamId}`),
      exerciseTypes: (teamId: number) => b(`/api/v1/admin/teams/${teamId}/exercise-types`),
    },

    exercises: {
      shiftInsights: (exerciseId: number) =>
        b(`/api/v1/admin/exercises/${exerciseId}/shift-insights`),
      deleteMemberResults: (exerciseId: number, memberId: number) =>
        b(`/api/v1/admin/exercises/${exerciseId}/members/${memberId}/answers`),
      importAlignAnswers: (destinationExerciseId: number) =>
        b(`/api/v1/admin/exercises/${destinationExerciseId}/import-answers`),
      reOpenExercise: (exerciseId: number) => b(`/api/v1/admin/exercises/${exerciseId}/re-open`),
    },

    exerciseReports: {
      statementPercentiles: (token: string) =>
        b(`/api/v1/admin/exercise-reports/${token}/statement-percentiles`),
    },

    organizations: {
      saveExerciseTypes: (orgId: number) =>
        b(`/api/v1/admin/organizations/${orgId}/exercise-types`),
      saveFeaturedExerciseTypes: (orgId: number) =>
        b(`/api/v1/admin/organizations/${orgId}/featured-exercise-types`),
      logo: (orgId: number) => b(`/api/v1/admin/organizations/${orgId}/logo`),
      emailDomains: (orgId: number) => b(`/api/v1/admin/organizations/${orgId}/email-domains`),
      secretCode: (orgId: number) => b(`/api/v1/admin/organizations/${orgId}/secret-code`),
    },

    contentSections: {
      updateContentSection: (sectionName: string) =>
        b(`/admin-panel/custom-blocks/${sectionName}/content`),
    },

    stats: {
      insights: () => b('/api/v1/admin/stats/insights'),
    },

    orgContent: {
      items: () => b('/api/v1/admin/org-contents/items'),
      orgs: () => b('/api/v1/admin/org-contents/orgs'),
      org: (orgId: number) => b(`/api/v1/admin/org-contents/orgs/${orgId}`),
      orgItems: (orgId: number) => b(`/api/v1/admin/org-contents/orgs/${orgId}/items`),
      saveOrgItem: (orgId: number, key: string) =>
        b(`/api/v1/admin/org-contents/orgs/${orgId}/items/${key}`),
      deleteOrgItem: (orgId: number, key: string) =>
        b(`/api/v1/admin/org-contents/orgs/${orgId}/items/${key}`),
    },

    pulses: {
      templates: {
        list: () => b('/api/v1/admin/pulses/templates/list'),
        all: () => b('/api/v1/admin/pulses/templates/all'),
        create: () => b('/api/v1/admin/pulses/templates'),
        createCategory: () => b('/api/v1/admin/pulses/templates/categories'),
        updateCategory: (categoryId: number) =>
          b(`/api/v1/admin/pulses/templates/categories/${categoryId}`),
        get: (templateId: number) => b(`/api/v1/admin/pulses/templates/${templateId}`),
        saveOrgTemplates: (orgId: number) =>
          b(`/api/v1/admin/pulses/organizations/${orgId}/templates`),
        saveTeamTemplates: (teamId: number) => b(`api/v1/admin/pulses/teams/${teamId}/templates`),
      },
    },

    entityBlocks: {
      list: (query?: URLParams) => b('/api/v1/system-security/entity-blocks', query),
      delete: (entityBlockId: number) =>
        b(`/api/v1/system-security/entity-blocks/${entityBlockId}`),
    },

    sessions: {
      list: (query?: URLParams) => b('/api/v1/system-security/session/search', query),
      details: (query?: URLParams) => b('/api/v1/system-security/session/show/details', query),
      delete: (sessionId: string) => b(`/api/v1/system-security/session/${sessionId}`),
      logoutSessions: (query?: URLParams) => b('/api/v1/system-security/session/logout', query),
    },
  },

  pairedNote: {
    list: (recipientId: number, recipientType: string, query?: URLParams) =>
      b(`/api/v1/paired-notes/${recipientType}/${recipientId}`, query),
    update: (pairedNoteId: number, query?: URLParams) =>
      b(`/api/v1/paired-notes/${pairedNoteId}`, query),
  },

  memberRating: {
    list: (memberId: number, ratingKey: string, memberRatingType: string) =>
      b(`/api/v1/member-ratings/${memberId}/${memberRatingType}/${ratingKey}`),
    rate: (memberId: number) => b(`/api/v1/member-ratings/${memberId}`),
  },

  discussionSpace: {
    show: (discussionSpaceID: number) => b(`/api/v1/discussion-spaces/${discussionSpaceID}`),

    showByToken: (token: string) => b(`/api/v1/discussion-spaces/${token}?token=${token}`),

    showByExerciseId: (exerciseId: number) =>
      b('/api/v1/discussion-spaces/for-exercise', exerciseId),

    state: (discussionSpaceID: number) =>
      b(`/api/v1/discussion-spaces/${discussionSpaceID}/websocket-state`),

    raiseHand: (discussionSpaceID: number, memberId: number) =>
      b(`/api/v1/discussion-spaces/${discussionSpaceID}/raise-hand/${memberId}`),

    agendaNotes: (discussionSpaceID: number, agendaID: number) =>
      b(`/api/v1/discussion-spaces/${discussionSpaceID}/agendas/${agendaID}/notes`),

    prompts: (discussionSpaceID: number) =>
      b(`/api/v1/discussion-spaces/${discussionSpaceID}/prompts`),

    tipFeedback: (discussionSpaceID: number) =>
      b(`/api/v1/discussion-spaces/${discussionSpaceID}/tips/feedback`),

    invite: (discussionSpaceID: number) =>
      b(`/api/v1/discussion-spaces/${discussionSpaceID}/invite`),

    actions: (discussionSpaceID: number) =>
      b(`api/v1/discussion-spaces/${discussionSpaceID}/actions`),

    agendas: {
      create: (discussionSpaceID: number) =>
        b(`/api/v1/discussion-spaces/${discussionSpaceID}/agendas`),
      update: (discussionSpaceID: number, agendaID: number) =>
        b(`/api/v1/discussion-spaces/${discussionSpaceID}/agendas/${agendaID}`),
      reorder: (discussionSpaceID: number, agendaID: number) =>
        b(`/api/v1/discussion-spaces/${discussionSpaceID}/agendas/${agendaID}/reorder-notes`),
    },

    notes: {
      note: (discussionSpaceID: number, agendaID: number, noteID: number) =>
        b(`/api/v1/discussion-spaces/${discussionSpaceID}/agendas/${agendaID}/notes/${noteID}`),
    },

    votes: {
      vote: (discussionSpaceID: number, entityType: string, entityID: number) =>
        b(`/api/v1/discussion-spaces/${discussionSpaceID}/vote/${entityType}/${entityID}`),

      list: (discussionSpaceID: number) =>
        b(`/api/v1/discussion-spaces/${discussionSpaceID}/votes`),
    },
  },

  teamGames: {
    create: (teamId: number) => b(`/api/v1/team-games/${teamId}`),
    getById: (teamId: number, teamGameId: number) =>
      b(`/api/v1/team-games/${teamId}/${teamGameId}`),
    stream: (teamId: number, teamGameId: number) =>
      b(`/api/v1/team-games/${teamId}/${teamGameId}/stream`),
    answers: (teamId: number, teamGameId: number) =>
      b(`/api/v1/team-games/${teamId}/${teamGameId}/answers`),
    byToken: (token: string) => b(`/api/v1/team-games/by-token/${token}`),
  },

  tracking: {
    navigation: (token?: string) => b('/track/event', { token }),
    trigger: () => b('api/v1/system/ui/event'),
  },

  parseCsv: () => b('/api/v1/files/csv/parse'),

  cookies: {
    managePreferences: () => b('api/v1/cookies/preferences'),
  },

  metabase: {
    dashboard: (orgId: number, name: string, query?: URLParams) =>
      b(`/api/v1/metabase/${orgId}/dashboard/${name}`, query),
    adminDashboard: (name: string, query?: URLParams) =>
      b(`/api/v1/metabase/admin/dashboard/${name}`, query),
  },

  orgNotice: {
    answers: (noticeId: number) => b(`/api/v1/org-notices/${noticeId}/answers`),
  },

  subscriptions: {
    orders: {
      list: (orgId: number, query?: URLParams) => b(`/api/v1/subscriptions/${orgId}/orders`, query),
      get: (orderId: number) => b(`/api/v1/subscriptions/orders/${orderId}`),
      create: (orgId: number) => b(`/api/v1/subscriptions/${orgId}/orders`),
    },
    items: {
      list: (orgId: number) => b(`/api/v1/subscriptions/${orgId}/items`),
    },
    member: {
      teamsStatus: (orgId: number) => b(`/api/v1/subscriptions/${orgId}/teams-subscription-status`),
      teamMembersWithoutSubscription: (orgId: number) =>
        b(`/api/v1/subscriptions/${orgId}/teammates-without-subscription`),
      askMyManagerForSubscription: (orgId: number) =>
        b(`/api/v1/subscriptions/${orgId}/ask-my-manager`),
      subscriptionStatus: (orgId: number) =>
        b(`/api/v1/subscriptions/${orgId}/member/subscription-status`),
    },
    organization: {
      status: (orgId: number) => b(`/api/v1/subscriptions/${orgId}/status`),
    },
  },
};

export default wrapBuilders(apiRoutes, ['api']);
